export function convertToQueryString(params: Record<string, any>): string {
	const queryParts: string[] = [];

	for (const key in params) {
		if (Array.isArray(params[key])) {
			params[key].forEach((value: string) => {
				queryParts.push(
					`${encodeURIComponent(key)}=${encodeURIComponent(value)}`
				);
			});
		} else {
			queryParts.push(
				`${encodeURIComponent(key)}=${encodeURIComponent(params[key])}`
			);
		}
	}

	return queryParts.join("&");
}