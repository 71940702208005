import { Canvas } from "@react-three/fiber";
import Experience from "./Experience";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

const Renderer = () => {
  const [explosionFactor, setExplosionFactor] = useState(50);
  const { search } = useLocation();

  const model = new URLSearchParams(search).get("model");

  return (
    <>
      <Canvas style={{ background: "#808080" }}>
        <Experience
          modelURL={`/3d-models/${model}.glb`}
          explosionFactor={explosionFactor}
        ></Experience>
      </Canvas>

      <input
        style={{
          position: "absolute",
          top: 20,
          left: 10,
          width: "100px",
          height: "100px",
        }}
        type="range"
        step={1}
        value={explosionFactor}
        max={90}
        onChange={(e) => {
          setExplosionFactor(Number(e.target.value));
        }}
      />
    </>
  );
};
export default Renderer;
