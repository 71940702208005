import {
  Menu,
  Tooltip,
  MenuButton,
  IconButton,
  MenuList,
  MenuItem,
  Text,
  HStack,
} from "@chakra-ui/react";

type GaraazChoserProps = {
  MenuItemOnChange: (option: any) => void;
  ButtonIcon: any;
  options: any[];
  displayKey: string;
  ButtonToolTipLabel: string;
  MenuButtonProps?: any;
  showIcon?:any
};

const GaraazChoser: React.FC<GaraazChoserProps> = ({
  MenuItemOnChange,
  ButtonIcon,
  ButtonToolTipLabel,
  options,
  displayKey,
  MenuButtonProps,
  showIcon
}) => {
  return (
    <Menu isLazy placement="top-start">
      <Tooltip label={ButtonToolTipLabel}>
        <MenuButton
          as={IconButton}
          aria-label={ButtonToolTipLabel}
          icon={ButtonIcon}
          variant="outline"
          bg={"#BBE6E3"}
          color={"black"}
          onFocus={(e) => e.preventDefault()}
          {...MenuButtonProps}
        />
      </Tooltip>

      {!!options?.length && (
        <MenuList maxHeight={"15em"} overflow={"scroll"}>
          {options?.map((option: any, index) => (
            <MenuItem
              key={index}
              onClick={() => {
                MenuItemOnChange(option);
              }}
            >
              {index + 1}.
              <HStack w={"100%"} justifyContent={"space-between"}>
                <Text fontSize={"md"} ml={1}>
                  {" "}
                  {option[displayKey]
                    ?.split(" ")
                    ?.map((item: any) =>
                      item ? item[0]?.toUpperCase() + item?.slice(1) : ""
                    )
                    ?.join(" ")}
                </Text>
                {showIcon && option.menuItemIcon  }
              </HStack>
            </MenuItem>
          ))}
        </MenuList>
      )}
    </Menu>
  );
};

export default GaraazChoser;
