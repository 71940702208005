import { Box, Flex, Image, Text } from "@chakra-ui/react";
import PageScaffold from "../../components/PageScaffold";
import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { convertToQueryString } from "../../utils/convertQuery";
import {
	COLUMN_TYPES,
	GaraazSpinner,
	GaraazTable,
} from "@svan-garaaz/reactsharedlib";
import services from "./services";
import MetricsInfo from "../Orders/components/MetricsInfo";
import { useGetOrderStatsByOrderHandler } from "../Orders/queryHooks";

const Index = () => {
	const TableHeading = [
		{
			header: "Order No",
			accessor: "orderNo",
			type: COLUMN_TYPES.FULLNAME,
			copyable: true,
		},
		{
			header: "Order Date",
			accessor: "orderDate",
			type: COLUMN_TYPES.DATE,
		},
		{
			header: "Customer Name",
			accessor: "customer.name",
			type: COLUMN_TYPES.NAME,
		},
	];

	const [query, setQuery] = useState("");
	const [pageTitle, setPageTitle] = useState("Stats");
	const [type, setType] = useState("unhandled");

	const [orderHandler, setOrderHandler] = useState<any>(null);

  const { data: orderStats, isLoading: loadingOrderStats } =
    useGetOrderStatsByOrderHandler();

	const row = [10, 20, 30, 50, 100, 150];
	const value = 50;

	const { orderHandlerId } = useParams<{ orderHandlerId: string }>();

	const postR2DStates = ["READY_TO_DISPATCH", "DELIVERED"];
	const preR2DStates = [
	  "NEW",
	  "ACK",
	  "ALLOCATED",
	  "RETURN_REQUESTED",
	  "CUSTOMER_RETURNED",
	];

	useEffect(() => {
		if (type && orderHandlerId) {
			let newTitle = "";
			let updatedParams: any = { isPopulated: true };
			updatedParams.orderHandlerId = orderHandlerId;

			switch (type) {
				case "unhandled":
					updatedParams.orderStatus = preR2DStates;
					newTitle = "Unhandled Orders";
					break;

				case "handled":
					updatedParams.orderStatus = postR2DStates;
					newTitle = "Handled Orders";
					break;

				case "cancelled":
					updatedParams.orderStatus = ["CANCELLED"];
					newTitle = "Cancelled Orders";
					break;

				case "returns":
					updatedParams.orderType = ["RETURN"];
					newTitle = "Return Orders";
					break;

				case "cbo":
					updatedParams.orderStatus = ["CBO"];
					newTitle = "CBO Orders";
					break;

				case "customer hold":
					updatedParams.orderStatus = ["CUSTOMER_HOLD"];
					newTitle = "Customer Hold Orders";
					break;

				case "delivered":
					updatedParams.orderStatus = ["DELIVERED"];
					newTitle = "Delivered Orders";
					break;

				case "cbo due":
					updatedParams.orderStatus = ["CBO"];
					updatedParams.maxLatestETA = new Date();
					newTitle = "CBO Due Orders";
					break;

				default:
					newTitle = "Orders";
			}

			let newQuery = convertToQueryString(updatedParams);
			setQuery(newQuery);
			setPageTitle(newTitle);
		}
	}, [orderHandlerId, type]);

	useEffect(() => {
    if (orderHandlerId && orderStats) {
      const controller = new AbortController();
      const fetchOrderHandler = async () => {
        try {
          const orderHandlers = orderStats?.filter(
            (orderStat: any) => orderStat.orderHandler._id === orderHandlerId
          );

          if (orderHandlers && orderHandlers.length > 0) {
            setOrderHandler(orderHandlers[0]);
          } else {
            console.log("OrderHandler not found:", orderHandlers);
            window.location.href = "/";
          }
        } catch (err) {
          if (err instanceof DOMException && err.name === "AbortError") {
            console.log("Fetch aborted");
          } else {
            console.error("Error fetching order handler:", err);
          }
        }
      };

      fetchOrderHandler();

      return () => controller.abort();
    }
  }, [orderHandlerId, orderStats]);

	const metricsData = [
		{
			label: "Unhandled",
			primary: orderHandler?.orders_unhandled_today,
			highlightPrimary: true,
			secondary: orderHandler?.orders_unhandled_total,
			highlightSecondary: true,
			onClick: () => setType("unhandled"),
		},
		{
			label: "Handled",
			primary: orderHandler?.orders_handled_today,
			secondary: orderHandler?.orders_handled_total,
			onClick: () => setType("handled"),
		},
		{
			label: "Cancelled",
			primary: orderHandler?.orders_cancelled_today,
			secondary: orderHandler?.orders_cancelled_total,
			onClick: () => setType("cancelled"),
		},
		{
			label: "Returns",
			primary: orderHandler?.orders_return_today,
			secondary: orderHandler?.orders_return_total,
			onClick: () => setType("returns"),
		},
		{
			label: "CBO",
			primary: orderHandler?.orders_cbo_today,
			secondary: orderHandler?.orders_cbo_total,
			onClick: () => setType("cbo"),
		},
		{
			label: "Customer Hold",
			primary: orderHandler?.orders_customer_hold_today,
			secondary: orderHandler?.orders_customer_hold_total,
			highlightSecondary: true,
			onClick: () => setType("customer hold"),
		},
		{
			label: "Delivered",
			primary: orderHandler?.orders_delivered_today,
			secondary: orderHandler?.orders_delivered_total,
			onClick: () => setType("delivered"),
		},
		{
			label: "CBO Due",
			primary: orderHandler?.CBODue_today,
			highlightPrimary: true,
			onClick: () => setType("cbo due"),
		},
	];

	return (
		<PageScaffold title={pageTitle} isHeaderVisible={false}>
			<Flex p={5} pt={10} gap={2} bg={"#FAFAFC"}>
				<>
					<Box
						width={"20%"}
						minWidth={"200px"}
						height={"100%"}
						display={"flex"}
						flexDirection={"column"}
						justifyContent={"space-around"}
						p={2}
						boxShadow={"rgba(99, 99, 99, 0.2) 0px 2px 8px 0px"}
						borderRadius={"10px"}
						zIndex={1}
					>
						<Box display={"flex"} flexDirection={"column"} p={2}>
							<Box
								width={"100%"}
								display={"flex"}
								justifyContent={"center"}
							>
								<Box
									width={"100px"}
									height={"100px"}
									borderRadius={"50%"}
									overflow={"clip"}
								>
									<Image
										width={"100%"}
										height={"100%"}
										src={orderHandler?.orderHandler?.picture[0]?.path}
										alt=""
										backgroundPosition={"center"}
									></Image>
								</Box>
							</Box>

							<Box mt={2} display={"flex"} justifyContent={"center"}>
								<Text fontSize={"xl"} fontWeight={"bold"}>
									{orderHandler?.orderHandler?.firstName ?? ""}{" "}
									{orderHandler?.orderHandler?.lastName ?? ""}
								</Text>
							</Box>
						</Box>
						<Box
							bgColor="white"
							flex={1}
							borderRadius="5px"
							mt={2}
							p={2}
							display="flex"
							flexDirection="column"
							alignItems="flex-start"
							justifyContent="space-between"
							maxHeight={"60%"}
						>
							{metricsData.map((metric, index) => (
								<MetricsInfo
									key={index}
									label={metric.label}
									primary={metric.primary}
									secondary={metric.secondary}
									highlightPrimary={metric.highlightPrimary}
									highlightSecondary={metric.highlightSecondary}
									primaryFontSize="lg"
									secondaryFontSize="md"
									labelFontSize="sm"
									clickable={true}
									isActive={type === metric.label.toLowerCase()}
									onClickFunction={metric.onClick}
									color={
										type === metric.label.toLowerCase()
											? "#1E293B"
											: "gray"
									}
									labelColor={
										type === metric.label.toLowerCase()
											? "#1E293B"
											: "gray"
									}
									labelFontWeight={
										type === metric.label.toLowerCase()
											? "semibold"
											: "medium"
									}
									padding={2}
								/>
							))}
						</Box>
					</Box>
				</>
				<Box
					width={"full"}
					display={"flex"}
					flexDirection={"column"}
					justifyContent={"space-around"}
					p={2}
					boxShadow={"rgba(99, 99, 99, 0.2) 0px 2px 8px 0px"}
					borderRadius={"10px"}
				>
					{query && orderHandler ? (
						<GaraazTable
							columns={TableHeading}
							url={`${process.env.REACT_APP_BASE_URL}/v2/orders?${query}`}
							limits={row}
							defaultRowLimit={value}
							dataAccessor="orders"
							authorizationAPItoken= {localStorage.getItem("auth_orca") || ""}
						/>
					) : (
						<GaraazSpinner />
					)}
				</Box>
			</Flex>
		</PageScaffold>
	);
};

export default Index;
