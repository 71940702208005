/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.5.2 public/3d-models/battery.glb -o src/views/Engine3D/components/Battery.jsx -r -t public -k 
*/

import { useGLTF } from "@react-three/drei";
import gsap from "gsap";
import { useEffect, useRef, useState } from "react";
import * as THREE from "three";

export function Battery({ explosionFactor }) {
  const { nodes, materials } = useGLTF("/3d-models/battery.glb");

  const group = useRef(null);
  const [distances, setDistances] = useState([]);

  const [isLoaded, setIsLoaded] = useState(false);

  let previousExplosionFactor = explosionFactor;

  useEffect(() => {
    const parts = group.current.children;

    var centers = [];

    parts.forEach(function (i) {
      i.geometry.computeBoundingSphere();
      centers.push(i.geometry.boundingSphere.center);
    });

    var center = new THREE.Vector3();
    centers.forEach(function (vec) {
      center = center.add(vec);
    });
    center.x /= centers.length;
    center.y /= centers.length;
    center.z /= centers.length;
    const distancesTemp = [];

    parts.forEach(function (i, v) {
      var childCenter = i.geometry.boundingSphere.center;
      var direction = childCenter.sub(center);

      direction = direction.normalize();

      const tl = gsap.timeline().to(
        i.position,
        {
          x: direction.x * 50,
          y: direction.y * 50,
          z: direction.z * 50,
          duration: 2,
        },
        0
      );
      distancesTemp.push({
        originalX: i.position.x,
        originalY: i.position.y,
        originalZ: i.position.z,
        explodedDistanceX: direction.x * 100,
        explodedDistanceY: direction.y * 100,
        explodedDistanceZ: direction.z * 100,
      });
    });
    setDistances(distancesTemp);
    setIsLoaded(true);
  }, []);

  useEffect(() => {
    if (isLoaded) {
      if (previousExplosionFactor < explosionFactor) {
        deplodeProduct();
      } else {
        explodeProduct();
      }
      previousExplosionFactor = explosionFactor;
    }
  }, [explosionFactor, isLoaded]);

  function explodeProduct() {
    const parts = group.current.children;
    parts.forEach(function (i, v) {
      i.position.x =
        distances[v].originalX +
        (distances[v].explodedDistanceX / 100) * explosionFactor;
      i.position.y =
        distances[v].originalY +
        (distances[v].explodedDistanceY / 100) * explosionFactor;
      i.position.z =
        distances[v].originalZ +
        (distances[v].explodedDistanceZ / 100) * explosionFactor;
    });
  }

  function deplodeProduct() {
    const parts = group.current.children;
    parts.forEach(function (i, v) {
      i.position.x =
        distances[v].originalX +
        (distances[v].explodedDistanceX / 100) * previousExplosionFactor -
        (distances[v].explodedDistanceX / 100) * explosionFactor;
      i.position.y =
        distances[v].originalY +
        (distances[v].explodedDistanceY / 100) * previousExplosionFactor -
        (distances[v].explodedDistanceY / 100) * explosionFactor;
      i.position.z =
        distances[v].originalZ +
        (distances[v].explodedDistanceZ / 100) * previousExplosionFactor -
        (distances[v].explodedDistanceZ / 100) * explosionFactor;
    });
  }

  return (
    <group dispose={null} ref={group} position={[0, 0, 0]} scale={0.01}>
      <lineSegments
        name="meshes0"
        geometry={nodes.meshes0.geometry}
        material={materials.Mat_1}
      />
      <mesh
        name="meshes0_1"
        geometry={nodes.meshes0_1.geometry}
        material={materials.Mat_1}
      />
      <mesh
        name="SOLID_node_1"
        geometry={nodes.SOLID_node_1.geometry}
        material={materials.Mat_1}
      />
      <mesh
        name="SOLID_node_3"
        geometry={nodes.SOLID_node_3.geometry}
        material={materials.Mat_1}
      />
      <mesh
        name="SOLID_node_5"
        geometry={nodes.SOLID_node_5.geometry}
        material={materials.Mat_1}
      />
      <mesh
        name="SOLID_node_7"
        geometry={nodes.SOLID_node_7.geometry}
        material={materials.Mat_1}
      />
      <lineSegments
        name="meshes5"
        geometry={nodes.meshes5.geometry}
        material={materials.Mat_1}
      />
      <mesh
        name="meshes5_1"
        geometry={nodes.meshes5_1.geometry}
        material={materials.Mat_1}
      />
      <mesh
        name="SOLID_node_11"
        geometry={nodes.SOLID_node_11.geometry}
        material={materials.Mat_1}
      />
      <mesh
        name="SOLID_node_13"
        geometry={nodes.SOLID_node_13.geometry}
        material={materials.Mat_1}
      />
      <mesh
        name="SOLID_node_15"
        geometry={nodes.SOLID_node_15.geometry}
        material={materials.Mat_1}
      />
      <mesh
        name="SOLID_node_17"
        geometry={nodes.SOLID_node_17.geometry}
        material={materials.Mat_1}
      />
      <mesh
        name="SOLID_node_19"
        geometry={nodes.SOLID_node_19.geometry}
        material={materials.Mat_1}
      />
    </group>
  );
}

useGLTF.preload("3d-models/battery.glb");
