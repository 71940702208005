import { OrbitControls } from "@react-three/drei";

import { Battery } from "./Battery";

type ExperienceProps = {
  modelURL: string;
  explosionFactor: number;
};

const Experience: React.FC<ExperienceProps> = ({
  modelURL,
  explosionFactor,
}) => {
  return (
    <>
      <OrbitControls></OrbitControls>
      <Battery explosionFactor={explosionFactor}></Battery>
    </>
  );
};

export default Experience;
