import { Box, Text } from "@chakra-ui/react";

interface MetricsInfoProps {
	label: string;
	primary: number;
	secondary?: number;
	color?: string;
	highlightPrimary?: boolean;
	highlightSecondary?: boolean;
	primaryFontSize?: string;
	secondaryFontSize?: string;
	primaryColor?: string;
	secondaryColor?: string;
	primaryFontWeight?: string;
	secondaryFontWeight?: string;
	labelFontSize?: string;
	labelColor?: string;
	labelFontWeight?: string;

	clickable?: boolean;
	onClickFunction?: any;
	isActive?: boolean;
	padding?: number;
	hoverBgColor?: string;
	bgColor?: string;
}

const MetricsInfo: React.FC<MetricsInfoProps> = ({
	label,
	primary,
	secondary,
	color = "gray",
	highlightPrimary = false,
	highlightSecondary = false,
	primaryFontSize = "xs",
	secondaryFontSize = "xs",
	primaryColor = highlightPrimary && primary > 0 ? "red" : color,
	secondaryColor = highlightSecondary && secondary && secondary > 0
		? "red"
		: color,
	primaryFontWeight = "normal",
	secondaryFontWeight = "normal",
	labelFontSize = "xs",
	labelColor = "grey",
	labelFontWeight = "medium",
	clickable = false,
	onClickFunction,
	isActive = false,
	padding = 0,
	hoverBgColor = "#1E293B05",
	bgColor = "#1E293B05"
}) => (
	<Box
		display="flex"
		alignItems="center"
		justifyContent="space-between"
		width="100%"
		mt={1}
		p={padding}
		cursor={clickable ? "pointer" : undefined}
		transition={clickable ? "transform 200ms ease-out" : undefined}
		_hover={
			clickable
				? { transform: "scale(1.02)", bg: !isActive ? hoverBgColor : "" }
				: undefined
		}
		_active={clickable ? { transform: "scale(0.98)" } : undefined}
		onClick={clickable ? onClickFunction : undefined}
		bg={isActive ? bgColor : ""}
		borderRadius={"sm"}
	>
		<Text
			fontSize={labelFontSize}
			color={labelColor}
			fontWeight={labelFontWeight}
			lineHeight="normal"
		>
			{label}
		</Text>
		<Text lineHeight={1} display="inline">
			<Text
				as="span"
				fontWeight={primaryFontWeight}
				lineHeight={"normal"}
				fontSize={primaryFontSize}
				color={primaryColor}
			>
				{primary}
			</Text>
			{secondary !== undefined && (
				<Text
					as="span"
					fontWeight={secondaryFontWeight}
					lineHeight={"normal"}
					fontSize={secondaryFontSize}
					color={secondaryColor}
				>
					/{secondary}
				</Text>
			)}
		</Text>
	</Box>
);

export default MetricsInfo;
