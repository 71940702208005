import { Route, Routes } from "react-router-dom";
import Hoops from "./components/Hoops";
import Renderer from "./components/Renderer";

const Index = () => {
  return (
    <Routes>
      <Route path="/hoops" element={<Hoops />}></Route>
      <Route path="/3-d" element={<Renderer></Renderer>}></Route>
    </Routes>
  );
};

export default Index;
