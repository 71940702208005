import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";

dayjs.extend(utc);
export const formatDate = (date: any) => {
  return dayjs(date).format("DD MMM");
};

export const formatDateTime = (date: any) => {
  return dayjs(date).format("hh:mm A, MMM DD, YYYY");
};

export const formatDateTimeIncludesYear = (date: any) => {
  return dayjs(date).format("DD MMM YYYY, hh:mm A");
};

export const formatDateForNotification = (date: any) => {
  return dayjs(date).format("DD MMM");
};

export const formatTime = (date: any) => {
  return dayjs(date).format("hh:mm A");
};

export const dateFormatForApi = (date: any) => {
  return date.split("/").reverse().join("-");
};

export const dateFormatInvoiceApi = (date: any) => {
  return date.split("-").reverse().join("/");
};

export const dateToday = dayjs().format("DD MMM YY");
export const firstDateOfMonth = dayjs().startOf("month").format("DD MMM YY");

export const dateTodayApiFormat = dayjs().format("YYYY-MM-DD");

export const firstDateOfMonthApiFormat = dayjs()
  .startOf("month")
  .format("YYYY-MM-DD");

export const minusFiveMinutesFromCurrentTime = () =>
  dayjs().subtract(5, "minute").format("YYYY-MM-DDTHH:mm:ss");

export function dateSecondisBeforeDateFirst(firstDate: any, secondDate: any) {
  const newFirstDate = dayjs(firstDate);
  const newSecondDate = dayjs(secondDate);
  if (secondDate.length > 11) {
    return newSecondDate.isBefore(newFirstDate, "minute");
  } else {
    return newSecondDate.isBefore(newFirstDate, "day");
  }
}

export const findLatestETAWithGivenTime = (orderETA: any[]) => {
  return orderETA.reduce((latest, etaObj) => {
    const etaGivenTime = dayjs(etaObj.etaGivenTime);
    const eta = dayjs(etaObj.eta);

    if (!latest || etaGivenTime.isAfter(latest.latestGivenTime)) {
      return {
        latestTime: eta,
        latestGivenTime: etaGivenTime,
      };
    }

    return latest;
  }, null);
};

export const formatAfterDateTime = (date: any) => {
  const today = dayjs().startOf("day");
  const filterDate = dayjs(date);
  if (
    filterDate &&
    (filterDate.isAfter(today, "day") || filterDate.isBefore(today, "day"))
  ) {
    return formatDateTimeIncludesYear(date);
  }
  return formatTime(date);
};

export const formatAfterDateTimeWithoutMonth = (date: any) => {
  const today = dayjs().startOf("day");
  const filterDate = dayjs(date);
  if (
    filterDate &&
    (filterDate.isAfter(today, "day") || filterDate.isBefore(today, "day"))
  ) {
    return formatDate(date);
  } else {
    return "";
  }
};

export const formatInDateMonthYearUTC = (dateInput: any) => {
  const date = new Date(dateInput);

  const day = date.getUTCDate().toString().padStart(2, '0');
  const month = (date.getUTCMonth() + 1).toString().padStart(2, '0'); 
  const year = date.getUTCFullYear().toString().slice(-2); 

  return `${day}-${month}-${year}`;
};

