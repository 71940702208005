const endpoints = {
  getOTP: (visitId: string, phoneNumber: string, mobileNumberPrefix: string) =>
    `/otp/?visitId=${visitId}&mobileNumber=${mobileNumberPrefix}${phoneNumber}`,
  visitId: "/visits",
  verifyOTP: (mobileNumber: string, otp: string, mobileNumberPrefix: string) =>
    `/otp/verify?mobileNumber=${mobileNumberPrefix}${mobileNumber}&otp=${otp}&role=ORCA_ADMIN`,
  placeOrder: "/orders",
  getGaraazOrder: (orderId: any) => `v1/orders/${orderId}`,
  cancelGaraazOrder: (orderId: any) => `v1/orders/${orderId}/cancel`,
  getOrderStatsByOrderHandler: `v1/order-handler/stats`,
  getOrderHandlerStats:(orderHandlerId:string)=> `v1/order-handler/stats?orderHandlerId=${orderHandlerId}`,
  allocateSupplierGaraazOrder: (orderId: any) =>
    `v1/orders/${orderId}/allocate`,
  garaazOrderCBO: (orderId: any) => `v1/orders/${orderId}/CBO`,
  placeGaraazOrder: "/v1/orders",
  getXpressCustomer: (search: string) =>
    `/v1/customers?search=${search}&isXpress=true`,
  getActiveSubscriptionCustomers: `/v1/customers?subscriptions=active`,
  addMedia: "/media",
  deleteMedia: (mediaId: string) => `/media/${mediaId}`,
  mobileConfig: "/mobile/config",
  searchXpressCustomer: `/v1/customers?isXpress=true&search=`,
  getAllGaraazOrders: "/v2/orders?isPopulated=true",
  setOrderRemarks: (orderId: string) => `/v1/orders/${orderId}/orderRemarks`,
  setOrderRemarksByOrderNo: (orderNo: string) =>
    `/v1/orders/${orderNo}/orderRemarks`,

  setOrderETA: (orderId: string) => `/v1/orders/${orderId}/orderETA`,
  mergeOrder: (orderNo: string) => `/v1/orders/${orderNo}/merge`,
  updateOrDeleteOrder: (orderId: string) => `/v1/orders/${orderId}`,
  insertOrUpdateGaraazOrderItemsPart: (orderId: string) =>
    `/orders/${orderId}/orderItems`,
  deleteGaraazOrderPart: (orderId: string) => `/orders/${orderId}/orderItems`,
  getSearchedPartListBulk: (
    parts: { partNumber: string; orderedQuantity: number }[]
  ) =>
    `/part/get-product-by-code-bulk/${parts
      .map((part) => part.partNumber)
      .join(",")}`,
  getSearchedPartList: (partNumber: string) =>
    `/part/get-product-by-code/${partNumber}`,
  getOrderHandlers: `/v1/grazeons/order-handlers`,
  getAllOrderHandlers: `/v1/users?userType=GARAAZ_PERSONNEL&userSubType=ORDER_HANDLER`,
  getAllSuppliersV2: "/v2/suppliers",
  getBrands: "/v1/brands",
  getMediaById: (id: string) => `/media/${id}`,
  missedEta: (orderId: any) => `/v1/orders/${orderId}/etaMissed`,
  missedAck: (orderId: any) => `/v1/orders/${orderId}/ackMissed`,
  missedETAProvide: (orderId: any) => `/v1/orders/${orderId}/etaProvideMissed`,
  exportOrderDetailsAsCsv: (orderId: any) =>
    `/v1/orders/${orderId}/export?option=csv`,
  getCars: `/v1/cars`,
  getCarById: (carId: any) => `/v1/cars/${carId}`,
  getSparePartInfo: (partId: any) => `/v1/spare-part/${partId}`,
  getIframe: `/output/index.html`,
  getCarPartDetails: (carId: any) => `/v1/cars/${carId}/parts`,
  missingCarPartInformation: (carId: any) =>
    `v1/cars/${carId}/missing-part-information`,
  getActiveGenerations: `v1/cars/generations`,
  addCarSparePartRequest: "/v1/car-spare-part-requests",
  verifyCarPart: (carId: any, UPN: string, partNumber: string) =>
    `/v1/cars/${carId}/parts/${UPN}/${partNumber}/verify`,
  unVerifyCarPart: (carId: any, UPN: string, partNumber: string) =>
    `/v1/cars/${carId}/parts/${UPN}/${partNumber}/unverify`,
  fetchModels: "/v1/cars/models",
  fetchMakers: "/v1/cars/makers",
  fetchGenerations: "/v1/cars/generations",
  getCustomerAllSubscriptions: (customerId: string) =>
    `/v1/customers/${customerId}/subscriptions/`,
};

export default endpoints;
