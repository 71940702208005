import {
  HStack,
  Button,
  Tooltip,
  IconButton,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverArrow,
  PopoverBody,
  Box,
  Text,
  useDisclosure,
  Portal,
  OrderedList,
  ListItem,
  UnorderedList,
  Link,
  Spinner,
} from "@chakra-ui/react";
import {
  PersonOutlineOutlined,
  ThumbDownAlt,
  AltRoute,
} from "@mui/icons-material";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { BiChevronDown } from "react-icons/bi";
import { BsThreeDots } from "react-icons/bs";
import {
  FaHourglassHalf,
  FaRegPauseCircle,
  FaRegPlayCircle,
} from "react-icons/fa";
import { FiAlertTriangle } from "react-icons/fi";
import CheckIcon from "@mui/icons-material/Check";
import { GiCardExchange, GiCrownedSkull, GiReturnArrow } from "react-icons/gi";
import { GrTransaction, GrUpload } from "react-icons/gr";
import { RiMenuAddFill } from "react-icons/ri";
import GaraazAlertDialogue from "../../../components/GaraazAlertDialogue";
import TextInputModal from "../../../components/TextInputModal";
import {
  globalOrderStatus,
  globalOrderActions,
  OrdersTextLabels,
} from "../../Orders/enums";
import GaraazChoser from "../../Orders/components/GaraazChoser";
import GaraazDatePickerModalETA from "../../Orders/components/GaraazDatePickerModalETA";
import GaraazGeneralPopover from "../../Orders/components/GaraazGeneralPopover";
import OrderCancellationModal from "../../Orders/components/OrderCancellationModal";
import OrderMergeModal from "../../Orders/components/OrderMergeModal";
import OrderReturnModal from "./OrderReturnModal";
import OrderSplitModal from "./OrderSplitModal";
import UploadInvoiceModal, {
  invoiceInfoType,
} from "../../Orders/components/UploadInvoiceModal";
import useOrdersStore from "../store/useOrdersStore";
import { useGetOrderMediaConfig } from "../../PlaceOrderTest/queryHooks";
import {
  useGetAllSuppliers,
  useGetSingleGaraazOrder,
} from "../../Orders/queryHooks";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import LowPriorityIcon from "@mui/icons-material/LowPriority";
import { AllocatedTo, startOrcaStates } from "./GaraazOrderComponent";
import ClearOutlinedIcon from "@mui/icons-material/ClearOutlined";
import ThumbUpAltIcon from "@mui/icons-material/ThumbUpAlt";
import CallMergeIcon from "@mui/icons-material/CallMerge";
import DeleteIcon from "@mui/icons-material/Delete";
import services from "../../Orders/services";
import useNotification, {
  useGaraazToastWithChildren,
} from "../../../utils/useNotification";
import OrderStatusChangeErrorBox from "../../Orders/components/OrderStatusChangeErrorBox";
import { useUserDetailsContext } from "../../../context/userDetailsContext";
import GaraazCountDownClockV2 from "../../Orders/components/GaraazCountDownClockV2";
import InvoicePopver from "../../Orders/components/InvoicePopover";
import SearchListModal from "../../Orders/components/SearchListModal";
import ReactCanvasConfetti from "react-canvas-confetti";
import { dateFormatInvoiceApi } from "../../../utils/dateResolvers";
import GarrazCopyText from "../../Orders/components/GaraazCopyText";
import GaraazErrorDialogue from "../../../components/GaraazErrorDialogue";
import getUserRole from "../../../utils/getUserRole";
import constants from "../../../utils/constants";
import { NavLink, useNavigate } from "react-router-dom";
import paths from "../../../utils/paths";
import { Roles } from "../../Orders/enums";
import Brightness1Icon from "@mui/icons-material/Brightness1";

type GaraazOrderComponentProps = {
  orderData: any;
  orderStatus: any;
  orderCancellationReason: string[];
  setOrderStatus: React.Dispatch<any>;
  handlePartConversionChangeScreen: () => void;
  handleTransactionTypeChange: (option: any) => Promise<void>;
  activesupplierOrderStatus: AllocatedTo;
  refetchAllOrders: () => void;
  setEtaMissed: React.Dispatch<React.SetStateAction<boolean>>;
  isCustomerOrderFreezed: boolean;
};

const GaraazOrderComponentFooter: React.FC<GaraazOrderComponentProps> = ({
  orderData,
  orderStatus,
  handlePartConversionChangeScreen,
  handleTransactionTypeChange,
  activesupplierOrderStatus,
  refetchAllOrders,
  orderCancellationReason,
  setOrderStatus,
  setEtaMissed,
  isCustomerOrderFreezed,
}) => {
  const toast = useNotification();
  const navigate = useNavigate();
  const garaazToast = useGaraazToastWithChildren();
  const userDetails = useUserDetailsContext();
  const role = getUserRole(userDetails);
  const orderEta = useOrdersStore((state) => state.orderEtas[orderData._id]);
  const setSelectedOrderHandler = useOrdersStore(
    (store) => store.setSelectedOrderHandler
  );
  const setOrderEtasWithOrderId = useOrdersStore(
    (state) => state.setOrderEtasWithOrderId
  );
  const savedOrderRemarks = useOrdersStore(
    (state) => state.orderRemarks[orderData._id]
  );
  const setSavedOrderRemarks = useOrdersStore(
    (state) => state.setOrderRemarksWithOrderId
  );

  const { data: mobileConfigs } = useGetOrderMediaConfig();
  const { data: allSuppliers } = useGetAllSuppliers();
  const { data: parentOrder } = useGetSingleGaraazOrder(
    orderData.parentOrderId
  );
  const orderHandlers = useOrdersStore((state) => state.orderHandlers);
  const orderItems = useOrdersStore((state) => state.orderItems[orderData._id]);
  const [samePartNumberOrders, setSamePartNumberOrders] = useState<any[]>([]);
  const reviewSamePartOrdersLinkRef = useRef<any>(null);
  const [isSamePartNumberOrdersLoading, setIsSamePartNumberOrdersLoading] =
    useState(false);
  const [invoiceInfo, setInvocieInfo] = useState({
    title: "Garaaz",
    invoiceMediaId: orderData?.invoiceInfo?.invoiceMediaId ?? "",
    invoiceNo: orderData?.invoiceInfo?.invoiceNo ?? "",
    invoiceAmount: orderData?.invoiceInfo?.invoiceAmount ?? "",
    invoiceDate: orderData?.invoiceInfo?.invoiceDate ?? "",
  });
  const [challanInfo, setChallanInfo] = useState({
    title: "Garaaz",
    challanMediaId: orderData?.challanInfo?.challanMediaId ?? "",
    challanNo: orderData?.challanInfo?.challanNo ?? "",
    challanAmount: orderData?.challanInfo?.challanAmount ?? "",
    challanDate: orderData?.challanInfo?.challanDate ?? "",
  });
  const [supplierInvoiceInfo, setSupplierInvoiceInfo] = useState<
    {
      invoiceMediaId: any;
      invoiceNo: any;
      invoiceAmount: any;
      invoiceDate: any;
    }[]
  >(orderData.allocatedTo?.map((item: any) => item.invoiceInfo));
  const [supplierChallanInfo, setSupplierChallanInfo] = useState<
    {
      challanMediaId: any;
      challanNo: any;
      challanAmount: any;
      challanDate: any;
    }[]
  >(orderData.allocatedTo?.map((item: any) => item.challanInfo));
  const [ackClockValue0, setAckClockValue0] = useState(
    Math.floor(new Date(orderData.orderDate).getTime() / 1000)
  );

  const [ackClockValue100, setAckClockValue100] = useState(
    Math.floor(new Date(orderData.orderDate).getTime() / 1000) +
      mobileConfigs?.threshold_ack_order_mins * 60
  );
  const [assignedSupplier, setAssignedSupplier] = useState<AllocatedTo[]>(
    orderData?.allocatedTo || []
  );

  const [cancellationReason, setCancellationReason] = useState(
    orderData?.cancellationReason ?? ""
  );
  const [returnOrders, setReturnOrders] = useState<any>([]);
  const [CBOOrders, setCBOOrders] = useState<any>([]);

  const [ackTimeMissed, setAckTimeMissed] = useState(false);
  const [parentOrderActiveSupplier, setParentOrderActiveSupplier] =
    useState<any>(null);

  const [filteredSuppliers, setFilteredSuppliers] = useState(allSuppliers);

  const orderHandlerOptions = orderHandlers.map((orderHandler) => {
    return {
      name: orderHandler.user.firstName + " " + orderHandler.user.lastName,
      menuItemIcon: (
        <Brightness1Icon
          sx={{
            color: orderHandler?.isActive ? "green" : "red",
            fontSize: "10px",
          }}
        />
      ),
      ...orderHandler,
    };
  });
  const orderHandlerName =
    (orderData?.orderHandler[0]?.firstName ?? "-") +
    " " +
    (orderData?.orderHandler[0]?.lastName ?? "");
  const transactionTypeOption = [{ type: "Facilitation" }, { type: "Trading" }];

  const {
    isOpen: etaPickerModalOpen,
    onOpen: openETAPickerModal,
    onClose: closeETAPickerModal,
  } = useDisclosure();

  const {
    isOpen: updateInvoiceModalOpen,
    onOpen: openUpdateInvoiceModal,
    onClose: closeUpdateInvoiceModal,
  } = useDisclosure();

  const {
    isOpen: orderRemarksModelOpen,
    onOpen: openOrdersRemarksModel,
    onClose: closeOrdersRemarksModel,
  } = useDisclosure();

  const {
    isOpen: isOpenCBOOrderDialogue,
    onOpen: onOpenCBOOrderDialogue,
    onClose: onCloseCBOOrderDialogue,
  } = useDisclosure();

  const {
    isOpen: isOpenREADY_TO_DISPATCHOrderDialogue,
    onOpen: onOpenREADY_TO_DISPATCHOrderDialogue,
    onClose: onCloseREADY_TO_DISPATCHOrderDialogue,
  } = useDisclosure();

  const {
    isOpen: isOpenCUSTOMER_HOLDOrderDialogue,
    onOpen: onOpenCUSTOMER_HOLDOrderDialogue,
    onClose: onCloseCUSTOMER_HOLDOrderDialogue,
  } = useDisclosure();

  const {
    isOpen: isOpenSamePartNumberFoundInOtherOrderDialogue,
    onOpen: onOpenSamePartNumberFoundInOtherOrderDialogue,
    onClose: onCloseSamePartNumberFoundInOtherOrderDialogue,
  } = useDisclosure();

  const {
    isOpen: isReturnOrderModalOpen,
    onOpen: onOpenReturnModal,
    onClose: onCloseReturnModal,
  } = useDisclosure();

  const {
    isOpen: orderCancellationReasonModalOpen,
    onOpen: openOrderCancellationReasonModal,
    onClose: closeOrderCancellationReasonModal,
  } = useDisclosure();
  const {
    isOpen: isOrderMergeModalOpen,
    onOpen: onOpenOrderMergeModal,
    onClose: onCloseOrderMergeModal,
  } = useDisclosure();
  const {
    isOpen: isOrderSplitModalOpen,
    onOpen: onOpenOrderSplitModal,
    onClose: onCloseOrderSplitModal,
  } = useDisclosure();
  const {
    isOpen: orderDeletionReasonModalOpen,
    onOpen: openOrderDeletionReasonModal,
    onClose: closeOrderDeletionReasonModal,
  } = useDisclosure();

  const {
    isOpen: isSupplierModalListOpen,
    onOpen: onSupplierModalListOpen,
    onClose: onSupplierModalListClose,
  } = useDisclosure();

  const refAnimationInstance: any = useRef(null);

  const getInstance = useCallback((instance: any) => {
    refAnimationInstance.current = instance;
  }, []);

  const makeShot = useCallback((particleRatio: any, opts: any) => {
    if (refAnimationInstance.current) {
      refAnimationInstance.current({
        ...opts,
        particleCount: Math.floor(1000 * particleRatio),
      });
    }
  }, []);

  const fire = useCallback(() => {
    makeShot(2, {
      spread: 360,
      angle: 90,
      startVelocity: 70,
      gravity: 0.5,
    });
  }, [makeShot]);

  useEffect(() => {
    setInvocieInfo({
      title: "Garaaz",
      invoiceMediaId: orderData?.invoiceInfo?.invoiceMediaId,
      invoiceNo: orderData?.invoiceInfo?.invoiceNo,
      invoiceDate: orderData?.invoiceInfo?.invoiceDate,
      invoiceAmount: orderData?.invoiceInfo?.invoiceAmount,
    });
    setChallanInfo({
      title: "Garaaz",
      challanMediaId: orderData?.challanInfo?.challanMediaId,
      challanNo: orderData?.challanInfo?.challanNo,
      challanDate: orderData?.challanInfo?.challanDate,
      challanAmount: orderData?.challanInfo?.challanAmount,
    });
    setSupplierInvoiceInfo(
      orderData.allocatedTo.map((item: any) => ({
        ...item.invoiceInfo,
        supplierId: item.supplierId,
      }))
    );
    setSupplierChallanInfo(
      orderData.allocatedTo.map((item: any) => ({
        ...item.challanInfo,
        supplierId: item.supplierId,
      }))
    );
    setAssignedSupplier(orderData?.allocatedTo);

    setAckClockValue0(
      Math.floor(new Date(orderData.orderDate).getTime() / 1000)
    );
    setAckClockValue100(
      Math.floor(new Date(orderData.orderDate).getTime() / 1000) +
        mobileConfigs?.threshold_ack_order_mins * 60
    );
    setAckTimeMissed(false);
  }, [orderData]);

  useEffect(() => {
    const uniqueBrands: any = new Set(
      orderItems?.map((item: any) => item.brandId)
    );
    const uniqueBrandsArray = Array.from(uniqueBrands);
    if (uniqueBrandsArray.some((item) => !item)) {
      setFilteredSuppliers(allSuppliers);
    } else {
      const filteredSupplierTemp = allSuppliers?.filter((supplier: any) => {
        for (let i = 0; i < uniqueBrandsArray.length; i++) {
          let index = supplier.brands.indexOf(uniqueBrandsArray[i]);
          if (index === -1) {
            return false;
          }
        }
        return true;
      });
      setFilteredSuppliers(filteredSupplierTemp);
    }
  }, [orderItems, orderData, allSuppliers]);

  const saveOrderRemarks = async (remarks: string) => {
    try {
      let newOrderRemarks = [
        ...savedOrderRemarks,
        {
          userName: `${userDetails.firstName} ${userDetails.lastName}`,
          remarks: remarks,
        },
      ];
      setSavedOrderRemarks(orderData._id, newOrderRemarks);
      await services.setOrderRemarks(orderData._id, {
        userName: `${userDetails.firstName} ${userDetails.lastName}`,
        remarks,
      });
    } catch (error) {}
  };

  const handleorderETAModalSubmit = async (
    etaGivenTime: string,
    eta: string,
    reason: string
  ) => {
    try {
      const newEtaChangeReason = [...orderEta, { etaGivenTime, eta, reason }];
      setOrderEtasWithOrderId(orderData._id, newEtaChangeReason);
      await services.setOrderETA(orderData._id, {
        etaGivenTime,
        eta,
        reason,
      });
      setEtaMissed(false);
    } catch (error) {}
  };

  const handleOrderHandlerChange = async (item: any) => {
    await services.updateOrder(orderData._id, {
      orderHandler: item.userId,
    });
    setSelectedOrderHandler(orderData._id, item.user);
  };

  const handleDeletionModalSubmit = async (inputtext: string) => {
    try {
      await services.deleteOrder(orderData._id, { deletionReason: inputtext });
      !!refetchAllOrders && refetchAllOrders();
      toast({
        description: OrdersTextLabels.ORDER_DELETED,
        status: "success",
      });
    } catch (error: any) {
      toast({
        description:
          error.response.data.errorMsg ?? OrdersTextLabels.SOMETHING_WENT_WRONG,
        status: "error",
      });
    }
  };

  const handleCancellationModalSubmit = async (input: string) => {
    try {
      setCancellationReason(input);
      const nextStateResponse = await services.cancelGaraazOrder(
        orderData._id,
        { cancellationReason: input }
      );

      setOrderStatus(globalOrderStatus.CANCELLED);
      !!refetchAllOrders && refetchAllOrders();
    } catch (error: any) {
      const errorFromResponse = error?.response?.data?.errorMsg;
      const title = !!errorFromResponse?.length
        ? errorFromResponse[0]?.title
        : OrdersTextLabels.ACCESS_DENIED_ORDER_STATUS_INVALID;
      garaazToast({
        title: title,
        status: "error",
        render: <OrderStatusChangeErrorBox errorMsg={errorFromResponse} />,
      });
    }
  };
  const handleAssignSupplierChange = async (item: any) => {
    try {
      const response = await services.allocateSupplierOrder(orderData._id, {
        supplierId: item._id,
      });

      setAssignedSupplier(response.allocatedTo);
      refetchAllOrders();
    } catch (error: any) {
      const errorFromResponse = error?.response?.data?.errorMsg;
      const title = !!errorFromResponse.length
        ? errorFromResponse[0]?.title
        : OrdersTextLabels.ACCESS_DENIED_ORDER_STATUS_INVALID;

      garaazToast({
        title: title,
        status: "error",
        render: <OrderStatusChangeErrorBox errorMsg={errorFromResponse} />,
      });
    }
  };
  const handleChangeOrderStatusToCBO = async (
    etaGivenTime: string,
    eta: string,
    reason: string,
    TobeAllocateSupplierId?: string,
    modalCloseFunction?: () => void
  ) => {
    try {
      const response = await services.garaazOrderCBO(orderData._id, {
        supplierId: TobeAllocateSupplierId,
      });
      setOrderStatus(globalOrderStatus.CBO);
      setAssignedSupplier(response.allocatedTo);
      await handleorderETAModalSubmit(etaGivenTime, eta, reason);
      !!refetchAllOrders && refetchAllOrders();
    } catch (error: any) {
      const errorFromResponse = error?.response?.data?.errorMsg;
      const title = errorFromResponse[0].title
        ? errorFromResponse[0].title
        : OrdersTextLabels.CBO_STATE_INVALID;
      garaazToast({
        title: title,
        status: "error",
        render: <OrderStatusChangeErrorBox errorMsg={errorFromResponse} />,
      });
    } finally {
      if (modalCloseFunction) modalCloseFunction();
    }
  };

  const checkSamePartNumberOrders = async (
    customerId: string,
    startDate: string,
    endDate: string
  ) => {
    const response = await services.getAllGaraazOrders(
      `&customerId=${customerId}&excludeOrderStatus=${globalOrderStatus.DELIVERED}&excludeOrderStatus=${globalOrderStatus.CANCELLED}&orderType=REGULAR&limit=500`
    );

    const partIds = new Set(orderItems.map((item: any) => item.partId));
    const ordersWithSamePartNumbers: any[] = [];

    response.orders.forEach((responseOrder: any) => {
      if (responseOrder.orderNo === orderData.orderNo) {
        return;
      }
      const responseOrderItems = responseOrder.orderItems || [];
      if (
        responseOrderItems.some((responseOrderItem: any) =>
          partIds.has(responseOrderItem.partId)
        )
      ) {
        ordersWithSamePartNumbers.push({
          orderNo: responseOrder.orderNo,
          orderHandlerName: `${responseOrder.orderHandler[0].firstName} ${responseOrder.orderHandler[0].lastName}`,
        });
      }
    });

    return ordersWithSamePartNumbers;
  };

  const handleChangeOrderStatus = async (
    status: globalOrderStatus | globalOrderActions,
    modalCloseFunction?: () => void
  ) => {
    try {
      await services.checkNextStateOfGaraazOrders(orderData._id, status);
      setOrderStatus(status);
      if (status === globalOrderStatus.READY_TO_DISPATCH) {
        fire();
      }
      !!refetchAllOrders && refetchAllOrders();
    } catch (error: any) {
      const errorFromResponse = error?.response?.data?.errorMsg;
      const title = !!errorFromResponse.length
        ? errorFromResponse[0]?.title
        : OrdersTextLabels.ACCESS_DENIED_ORDER_STATUS_INVALID;
      garaazToast({
        title: title,
        status: "error",
        render: <OrderStatusChangeErrorBox errorMsg={errorFromResponse} />,
      });
    } finally {
      if (modalCloseFunction) modalCloseFunction();
    }
  };

  const handleUpdateInvoiceModalSubmit = async (
    pdfFile: File | null = null,
    invoiceInfo: invoiceInfoType,
    challanInfo: any,
    fileType: string
  ) => {
    try {
      let updatedParts = {};
      if (fileType === "Invoice") {
        if (
          pdfFile !== null &&
          invoiceInfo.invoiceNo &&
          invoiceInfo.invoiceAmount
        ) {
          const formData = new FormData();
          formData.append("media", pdfFile);
          const mediaResult = await services.addMedia(formData);

          updatedParts = {
            invoiceInfo: {
              ...invoiceInfo,
              invoiceMediaId: mediaResult[0]._id,
              invoiceDate: dateFormatInvoiceApi(invoiceInfo.invoiceDate),
            },
          };
        } else {
          updatedParts = {
            invoiceInfo: {
              ...invoiceInfo,
              invoiceDate: dateFormatInvoiceApi(invoiceInfo.invoiceDate),
              invoiceMediaId:
                invoiceInfo.invoiceNo &&
                invoiceInfo.invoiceAmount &&
                invoiceInfo.invoiceDate
                  ? orderData?.invoiceInfo?.invoiceMediaId
                  : undefined,
            },
          };
        }

        const updatedOrderResponse = await services.updateOrder(
          orderData._id,
          updatedParts
        );

        setInvocieInfo({
          title: "Garaaz",
          invoiceMediaId: updatedOrderResponse.invoiceInfo.invoiceMediaId,
          invoiceNo: updatedOrderResponse.invoiceInfo.invoiceNo,
          invoiceAmount: updatedOrderResponse.invoiceInfo.invoiceAmount,
          invoiceDate: updatedOrderResponse.invoiceInfo.invoiceDate,
        });

        toast({
          description: OrdersTextLabels.INVOICE_UPLOADED_SUCCESSFULLY,
          status: "success",
        });
      } else {
        if (
          pdfFile !== null &&
          challanInfo.challanNo &&
          challanInfo.challanAmount
        ) {
          const formData = new FormData();
          formData.append("media", pdfFile);
          const mediaResult = await services.addMedia(formData);

          updatedParts = {
            challanInfo: {
              ...challanInfo,
              challanMediaId: mediaResult[0]._id,
              challanDate: dateFormatInvoiceApi(challanInfo.challanDate),
            },
          };
        } else {
          updatedParts = {
            challanInfo: {
              ...challanInfo,
              challanDate: dateFormatInvoiceApi(challanInfo.challanDate),
              challanMediaId:
                challanInfo.challanNo &&
                challanInfo.challanAmount &&
                challanInfo.challanDate
                  ? orderData?.challanInfo?.challanMediaId
                  : undefined,
            },
          };
        }

        const updatedOrderResponse = await services.updateOrder(
          orderData._id,
          updatedParts
        );

        setChallanInfo({
          title: "Garaaz",
          challanMediaId: updatedOrderResponse.challanInfo.challanMediaId,
          challanNo: updatedOrderResponse.challanInfo.challanNo,
          challanAmount: updatedOrderResponse.challanInfo.challanAmount,
          challanDate: updatedOrderResponse.challanInfo.challanDate,
        });

        toast({
          description: OrdersTextLabels.CHALLAN_UPLOADED_SUCCESSFULLY,
          status: "success",
        });
      }
    } catch (error) {
      toast({
        description: OrdersTextLabels.SOMETHING_WENT_WRONG,
        status: "error",
      });
    }
  };

  const uniqueNumbersInQuickEtas = useMemo(() => {
    return mobileConfigs?.quick_etas_mins
      ?.split(",")
      ?.filter(
        (value: any, index: number, array: any) =>
          array.indexOf(value) === index && !isNaN(parseInt(value))
      );
  }, [mobileConfigs]);
  const quickEtasCBOOrderDays = useMemo(() => {
    return mobileConfigs?.quick_etas_cbo_order_days
      ?.split(",")
      ?.filter(
        (value: any, index: number, array: any) =>
          array.indexOf(value) === index && !isNaN(parseInt(value))
      );
  }, [mobileConfigs]);

  async function onAckTimeComplete() {
    const response = await services.throwACKMissedEvent(orderData._id);
  }

  async function loadReturnOrders() {
    const response = await services.getReturnedOrderItems(orderData._id);
    setReturnOrders(response);
  }

  async function loadCBOOrders() {
    const response = await services.getCBOOrders(orderData._id);
    setCBOOrders(response);
  }

  return (
    <HStack
      flex={1}
      height={"12%"}
      borderRadius={"0px 0px 8px 8px"}
      borderTop={"1px solid lightgrey"}
      style={{
        margin: 0,
      }}
      display={"flex"}
      padding={`${startOrcaStates.includes(orderStatus) ? 0 : "0.8rem"}`}
      py={`${startOrcaStates.includes(orderStatus) ? 0 : "1.5rem"}`}
      alignItems={"center"}
      bgColor={"white"}
    >
      {startOrcaStates.includes(orderStatus) ? (
        <Button
          borderTopLeftRadius={0}
          borderTopRightRadius={0}
          height={"100%"}
          width={"100%"}
          bg={"green.500"}
          _hover={{ bg: "green.600" }}
          color={"white"}
          onClick={() => handleChangeOrderStatus(globalOrderStatus.ACK)}
        >
          <Text fontSize={"lg"}>ACK</Text>
          {!orderEta.length && (
            <Box ml="5" display={"flex"}>
              {!ackTimeMissed && (
                <GaraazCountDownClockV2
                  componentSize="small"
                  value0={ackClockValue0}
                  value100={ackClockValue100}
                  onCompleteTime={onAckTimeComplete}
                  isCompleted={ackTimeMissed}
                  setIsCompleted={setAckTimeMissed}
                ></GaraazCountDownClockV2>
              )}

              {ackTimeMissed && (
                <HStack spacing={1}>
                  <FiAlertTriangle color="red" style={{ marginLeft: "2px" }} />
                  <Text>ACK Missed</Text>
                </HStack>
              )}
            </Box>
          )}
        </Button>
      ) : (
        <>
          <HStack spacing={3} w="45%">
            <Box>
              <Tooltip label="Flip View">
                <IconButton
                  icon={<GiCardExchange />}
                  backgroundColor={"#BBE6E3"}
                  color={"black"}
                  aria-label={""}
                  onClick={handlePartConversionChangeScreen}
                />
              </Tooltip>
            </Box>
            <Box>
              <GaraazChoser
                ButtonIcon={<PersonOutlineOutlined />}
                ButtonToolTipLabel="Assign Order Handler"
                MenuItemOnChange={handleOrderHandlerChange}
                displayKey="name"
                options={orderHandlerOptions}
                MenuButtonProps={{
                  isDisabled:
                    orderStatus === globalOrderStatus.READY_TO_DISPATCH ||
                    orderStatus === globalOrderStatus.DELIVERED ||
                    isCustomerOrderFreezed,
                }}
                showIcon={true}
              />
            </Box>

            <Box>
              <GaraazChoser
                ButtonIcon={<GrTransaction></GrTransaction>}
                ButtonToolTipLabel="Transaction Type"
                MenuItemOnChange={handleTransactionTypeChange}
                displayKey="type"
                options={transactionTypeOption}
                MenuButtonProps={{ isDisabled: isCustomerOrderFreezed }}
              />
            </Box>
            <Box>
              <Tooltip label="Assign Supplier">
                <IconButton
                  icon={<GiCrownedSkull />}
                  backgroundColor={"#BBE6E3"}
                  color={"black"}
                  aria-label={""}
                  onClick={onSupplierModalListOpen}
                  isDisabled={isCustomerOrderFreezed}
                />
              </Tooltip>

              <SearchListModal
                modalHeading="Assign Supplier"
                options={filteredSuppliers}
                isOpen={isSupplierModalListOpen}
                onClose={onSupplierModalListClose}
                handleSelectListItem={handleAssignSupplierChange}
                displayKey="businessName"
                searchInputPlaceholder="Search Supplier By Name"
                zeroOptionsMessage={
                  <>
                    <Text>
                      No Supplier Found that caters to the specified brands.
                    </Text>
                    <Text>Split the Order or find new supplier.</Text>
                  </>
                }
              ></SearchListModal>
            </Box>
            <Box>
              <Tooltip label="ETA">
                <IconButton
                  icon={<FaHourglassHalf />}
                  backgroundColor={"#BBE6E3"}
                  color={"black"}
                  aria-label={""}
                  onClick={openETAPickerModal}
                  isDisabled={isCustomerOrderFreezed}
                />
              </Tooltip>
              <GaraazDatePickerModalETA
                modelHeader="Please provide ETA for Customer"
                isOpen={etaPickerModalOpen}
                orderETAs={orderEta}
                handleSubmit={handleorderETAModalSubmit}
                onClose={closeETAPickerModal}
                modifiedOrderETAs={uniqueNumbersInQuickEtas}
              />
            </Box>
            <Box>
              <Tooltip label="Upload Invoice">
                <IconButton
                  isDisabled={
                    activesupplierOrderStatus?.orderStatus !==
                      globalOrderStatus.READY || isCustomerOrderFreezed
                  }
                  icon={<GrUpload />}
                  backgroundColor={"#BBE6E3"}
                  color={"black"}
                  aria-label={""}
                  onClick={openUpdateInvoiceModal}
                />
              </Tooltip>
              <UploadInvoiceModal
                isOpen={updateInvoiceModalOpen}
                orderInvoiceInfo={invoiceInfo}
                orderChallanInfo={challanInfo}
                handleSubmit={handleUpdateInvoiceModalSubmit}
                onClose={closeUpdateInvoiceModal}
              />
            </Box>
            <Box>
              <Tooltip label="Add Order Remarks">
                <IconButton
                  icon={<RiMenuAddFill />}
                  backgroundColor={"#BBE6E3"}
                  color={"black"}
                  aria-label={""}
                  onClick={openOrdersRemarksModel}
                  isDisabled={isCustomerOrderFreezed}
                />
              </Tooltip>
              <TextInputModal
                modalHeader="Order Remarks"
                inputPlaceHolder="Add Order Remarks"
                isOpen={orderRemarksModelOpen}
                onSave={saveOrderRemarks}
                onClose={closeOrdersRemarksModel}
              />
            </Box>

            <Popover placement="top-start">
              <PopoverTrigger>
                <Box>
                  <Tooltip label="Meta Actions">
                    <IconButton
                      icon={<CheckCircleOutlineIcon />}
                      backgroundColor={"#BBE6E3"}
                      color={"black"}
                      aria-label={""}
                      isDisabled={isCustomerOrderFreezed}
                    />
                  </Tooltip>
                </Box>
              </PopoverTrigger>

              <PopoverContent width={"16rem"}>
                <PopoverArrow />

                <PopoverBody
                  display={"flex"}
                  width={"100%"}
                  justifyContent={"space-between"}
                >
                  {" "}
                  <>
                    <Box>
                      <Tooltip label="Ready to Dispatch Order">
                        <IconButton
                          icon={
                            isSamePartNumberOrdersLoading ? (
                              <Spinner></Spinner>
                            ) : (
                              <CheckIcon />
                            )
                          }
                          backgroundColor={"#BBE6E3"}
                          color={"black"}
                          aria-label={""}
                          onClick={async () => {
                            setIsSamePartNumberOrdersLoading(true);
                            const ordersWithSamePartNumbers =
                              await checkSamePartNumberOrders(
                                orderData.customerId,
                                orderData.orderDate,
                                orderData.orderDate
                              );
                            setIsSamePartNumberOrdersLoading(false);
                            setSamePartNumberOrders(ordersWithSamePartNumbers);
                            if (ordersWithSamePartNumbers.length === 0) {
                              onOpenREADY_TO_DISPATCHOrderDialogue();
                            } else {
                              onOpenSamePartNumberFoundInOtherOrderDialogue();
                            }
                          }}
                        />
                      </Tooltip>
                      <GaraazAlertDialogue
                        alertBodyText="Are you sure? You can't undo this action afterwards."
                        alertDialogueHeaderText="Mark as Ready to Dispatch"
                        onSubmitBtn={() =>
                          handleChangeOrderStatus(
                            globalOrderStatus.READY_TO_DISPATCH,
                            onCloseREADY_TO_DISPATCHOrderDialogue
                          )
                        }
                        isOpen={isOpenREADY_TO_DISPATCHOrderDialogue}
                        onClose={onCloseREADY_TO_DISPATCHOrderDialogue}
                        onOpen={onOpenREADY_TO_DISPATCHOrderDialogue}
                        submitBtnText="Confirm"
                      />
                      <GaraazErrorDialogue
                        alertBodyText={
                          <Box display={"flex"} flexDirection={"column"}>
                            <Text>
                              We noticed similar part numbers in others orders
                              from {orderData.customer.name}. To ensure
                              accuracy, please review the following orders
                              before confirming.
                            </Text>
                            <UnorderedList mt={3} mb={3}>
                              {samePartNumberOrders.map((order) => {
                                return (
                                  <ListItem>
                                    {" "}
                                    <Box
                                      key={order.orderNo}
                                      display={"flex"}
                                      alignItems={"center"}
                                    >
                                      <Text>{order.orderNo}</Text>
                                      <GarrazCopyText
                                        popOverMessage="Order No Copied Successfully !"
                                        text={order.orderNo}
                                      ></GarrazCopyText>
                                      <Text ml={1}>
                                        {" "}
                                        : {order.orderHandlerName}
                                      </Text>
                                    </Box>
                                  </ListItem>
                                );
                              })}
                            </UnorderedList>
                          </Box>
                        }
                        alertGenericHeaderText="Hold on! Similar Part Numbers Detected"
                        isOpen={isOpenSamePartNumberFoundInOtherOrderDialogue}
                        onClose={() => {
                          reviewSamePartOrdersLinkRef.current.click();
                          onCloseSamePartNumberFoundInOtherOrderDialogue();
                        }}
                        onOpen={onOpenSamePartNumberFoundInOtherOrderDialogue}
                        onSubmitBtn={() =>
                          handleChangeOrderStatus(
                            globalOrderStatus.READY_TO_DISPATCH,
                            onCloseSamePartNumberFoundInOtherOrderDialogue
                          )
                        }
                        submitBtnText="Confirm"
                        leftBtnText="Review Orders"
                      ></GaraazErrorDialogue>
                      <Link
                        target="_blank"
                        as={NavLink}
                        to={`${
                          paths.samePartOrders
                        }?orderNo=${samePartNumberOrders
                          .map((order) => order.orderNo)
                          .join("&orderNo=")}`}
                        ref={reviewSamePartOrdersLinkRef}
                      ></Link>
                      <Portal>
                        <ReactCanvasConfetti
                          refConfetti={getInstance}
                          style={{
                            position: "fixed",
                            pointerEvents: "none",
                            width: "100vw",
                            height: "100vh",
                            top: 0,
                            left: 0,
                            zIndex: 10000,
                          }}
                        />
                      </Portal>
                    </Box>
                    <Box>
                      <Tooltip label="CBO Order">
                        <IconButton
                          icon={<LowPriorityIcon />}
                          backgroundColor={"#BBE6E3"}
                          color={"black"}
                          aria-label={""}
                          onClick={onOpenCBOOrderDialogue}
                        />
                      </Tooltip>
                      <GaraazDatePickerModalETA
                        modelHeader="Please provide ETA for CBO"
                        isOpen={isOpenCBOOrderDialogue}
                        onClose={onCloseCBOOrderDialogue}
                        orderETAs={orderEta}
                        okBtnText="Confirm"
                        handleSubmit={handleChangeOrderStatusToCBO}
                        modifiedOrderETAs={quickEtasCBOOrderDays}
                        allAlocatedSuppliers={assignedSupplier}
                        activeSupplierId={activesupplierOrderStatus?.supplierId}
                        isCBOETAPicker={true}
                      />
                    </Box>
                    <Box>
                      <Tooltip
                        label={`${
                          orderStatus === globalOrderStatus.CUSTOMER_HOLD
                            ? "Resume"
                            : "Customer Hold"
                        }`}
                      >
                        <IconButton
                          icon={
                            orderStatus === globalOrderStatus.CUSTOMER_HOLD ? (
                              <FaRegPlayCircle></FaRegPlayCircle>
                            ) : (
                              <FaRegPauseCircle />
                            )
                          }
                          backgroundColor={"#BBE6E3"}
                          color={"black"}
                          aria-label={""}
                          onClick={onOpenCUSTOMER_HOLDOrderDialogue}
                        />
                      </Tooltip>
                      <GaraazAlertDialogue
                        alertBodyText={
                          orderStatus === globalOrderStatus.CUSTOMER_HOLD
                            ? "This action will remove the ‘Customer Hold’ status and allow the order to proceed."
                            : "This action will pause the order processing until further notice from the customer."
                        }
                        alertDialogueHeaderText={`${
                          orderStatus === globalOrderStatus.CUSTOMER_HOLD
                            ? "Resume"
                            : "Customer Hold"
                        }`}
                        onSubmitBtn={async () => {
                          await handleChangeOrderStatus(
                            orderStatus === globalOrderStatus.CUSTOMER_HOLD
                              ? globalOrderActions.CUSTOMER_UNHOLD
                              : globalOrderStatus.CUSTOMER_HOLD,
                            onCloseCUSTOMER_HOLDOrderDialogue
                          );
                        }}
                        isOpen={isOpenCUSTOMER_HOLDOrderDialogue}
                        onClose={onCloseCUSTOMER_HOLDOrderDialogue}
                        onOpen={onOpenCUSTOMER_HOLDOrderDialogue}
                        submitBtnText="Confirm"
                      />
                    </Box>
                    <Box>
                      <Tooltip label="Return Order">
                        <IconButton
                          icon={<GiReturnArrow />}
                          backgroundColor={"#BBE6E3"}
                          color={"black"}
                          aria-label={""}
                          onClick={onOpenReturnModal}
                          isDisabled={
                            orderStatus !== globalOrderStatus.DELIVERED
                          }
                        />
                      </Tooltip>
                      <OrderReturnModal
                        orderData={orderData}
                        orderId={orderData._id}
                        isOpen={isReturnOrderModalOpen}
                        onClose={onCloseReturnModal}
                        refetchAllOrders={refetchAllOrders}
                      ></OrderReturnModal>
                    </Box>

                    <Box>
                      <Tooltip label="Cancel Order">
                        <IconButton
                          icon={<ClearOutlinedIcon />}
                          backgroundColor={"#BBE6E3"}
                          color={"black"}
                          aria-label={""}
                          onClick={openOrderCancellationReasonModal}
                        />
                      </Tooltip>
                      <OrderCancellationModal
                        orderCacellationReasons={orderCancellationReason}
                        isOpen={orderCancellationReasonModalOpen}
                        handleSubmit={handleCancellationModalSubmit}
                        onClose={closeOrderCancellationReasonModal}
                      />
                    </Box>
                  </>
                </PopoverBody>
              </PopoverContent>
            </Popover>

            <Popover placement="top-start">
              <PopoverTrigger>
                <Box>
                  <Tooltip label="Advanced Actions">
                    <IconButton
                      isDisabled={
                        (role !== constants.SUPER_ADMIN &&
                          orderStatus ===
                            globalOrderStatus.READY_TO_DISPATCH) ||
                        orderStatus === globalOrderStatus.DELIVERED ||
                        isCustomerOrderFreezed
                      }
                      icon={<BsThreeDots />}
                      backgroundColor={"#BBE6E3"}
                      color={"black"}
                      aria-label={""}
                    />
                  </Tooltip>
                </Box>
              </PopoverTrigger>

              <PopoverContent width={"10rem"}>
                <PopoverArrow />

                <PopoverBody
                  display={"flex"}
                  width={"100%"}
                  justifyContent={"space-between"}
                >
                  <Box>
                    <Tooltip label="Merge Order">
                      <IconButton
                        icon={<CallMergeIcon></CallMergeIcon>}
                        backgroundColor={"#BBE6E3"}
                        color={"black"}
                        aria-label={""}
                        onClick={onOpenOrderMergeModal}
                        isDisabled={orderStatus !== globalOrderStatus.ACK}
                      />
                    </Tooltip>
                    <OrderMergeModal
                      isOpen={isOrderMergeModalOpen}
                      onClose={onCloseOrderMergeModal}
                      refetchAllOrders={refetchAllOrders}
                      orderData={orderData}
                    />
                  </Box>

                  <Box>
                    <Tooltip label="Split Order">
                      <IconButton
                        icon={<AltRoute></AltRoute>}
                        backgroundColor={"#BBE6E3"}
                        color={"black"}
                        aria-label={""}
                        onClick={onOpenOrderSplitModal}
                        isDisabled={orderStatus !== globalOrderStatus.ACK}
                      />
                    </Tooltip>
                    <OrderSplitModal
                      isOpen={isOrderSplitModalOpen}
                      onClose={onCloseOrderSplitModal}
                      orderId={orderData?._id}
                      orderData={orderData}
                      refetchAllOrders={refetchAllOrders}
                    />
                  </Box>

                  <Box>
                    <Tooltip label="Delete Order">
                      <IconButton
                        icon={<DeleteIcon />}
                        backgroundColor={"#BBE6E3"}
                        color={"black"}
                        aria-label={""}
                        onClick={openOrderDeletionReasonModal}
                        isDisabled={
                          !(
                            userDetails.userSubType === Roles.EXECUTIVE ||
                            userDetails.userSubType === Roles.OPERATIONS_LEAD
                          )
                        }
                      />
                    </Tooltip>
                    <TextInputModal
                      modalHeader="Are you sure ?"
                      inputPlaceHolder="Add deletion reason"
                      isOpen={orderDeletionReasonModalOpen}
                      onSave={handleDeletionModalSubmit}
                      onClose={closeOrderDeletionReasonModal}
                      showActionText={true}
                      submitBtnText="Confirm"
                      cancelBtnText="Cancel"
                    />
                  </Box>
                </PopoverBody>
              </PopoverContent>
            </Popover>
          </HStack>
          <Box
            display={"flex"}
            style={{ marginLeft: "auto" }}
            paddingTop={orderStatus === globalOrderStatus.NEW ? 5 : 2.5}
          >
            <GaraazGeneralPopover
              ArekeysVisible={false}
              CopierKeys={["orderNo"]}
              title="Return Orders"
              value={returnOrders.map((order: any) => ({
                orderNo: order.orderNo,
              }))}
              placement={"top-start"}
            >
              <PopoverTrigger>
                <Text
                  mr={5}
                  fontWeight={"bold"}
                  color={"#1A202C"}
                  cursor={"pointer"}
                  display={"flex"}
                  onMouseEnter={() => {
                    loadReturnOrders();
                  }}
                >
                  RETURNS
                  <BiChevronDown
                    style={{ display: "inline", fontSize: "1.5em" }}
                  />
                </Text>
              </PopoverTrigger>
            </GaraazGeneralPopover>
            {!orderData.parentOrderId && (
              <GaraazGeneralPopover
                ArekeysVisible={false}
                CopierKeys={["orderNo"]}
                title="CBO Orders"
                value={CBOOrders?.map((order: any) => ({
                  orderNo: order.orderNo,
                }))}
                placement={"top-start"}
              >
                <PopoverTrigger>
                  <Text
                    fontWeight={"bold"}
                    color={"#1A202C"}
                    cursor={"pointer"}
                    display={"flex"}
                    onMouseEnter={() => {
                      loadCBOOrders();
                    }}
                  >
                    CBO
                    <BiChevronDown
                      style={{ display: "inline", fontSize: "1.5em" }}
                    />
                  </Text>
                </PopoverTrigger>
              </GaraazGeneralPopover>
            )}

            {orderData?.parentOrderId && (
              <GaraazGeneralPopover
                ArekeysVisible={false}
                CopierKeys={["orderNo"]}
                title="Parent CBO Order Number"
                value={[{ orderNo: parentOrder?.orderNo }]}
                placement={"top-start"}
              >
                <PopoverTrigger>
                  <Text
                    fontWeight={"bold"}
                    color={"#1A202C"}
                    cursor={"pointer"}
                    display={"flex"}
                  >
                    P-CBO
                    <BiChevronDown
                      style={{ display: "inline", fontSize: "1.5em" }}
                    />
                  </Text>
                </PopoverTrigger>
              </GaraazGeneralPopover>
            )}

            {(invoiceInfo?.invoiceNo ||
              supplierInvoiceInfo.some((item: any) => item?.invoiceNo) ||
              challanInfo?.challanNo ||
              supplierChallanInfo.some((item: any) => item?.challanNo)) && (
              <InvoicePopver
                copierKeys={["invoiceNo", "challanNo"]}
                Invoices={[invoiceInfo, ...supplierInvoiceInfo]}
                Challans={[challanInfo, ...supplierChallanInfo]}
              >
                <PopoverTrigger>
                  <Text
                    fontWeight={"bold"}
                    color={"#1A202C"}
                    cursor={"pointer"}
                    ml={5}
                    display={"flex"}
                  >
                    INVOICE
                    <BiChevronDown
                      style={{ display: "inline", fontSize: "1.5em" }}
                    />
                  </Text>
                </PopoverTrigger>
              </InvoicePopver>
            )}
            <Box ml={5}>
              <Text fontWeight={"bold"} color={"#1A202C"}>
                HANDLER
              </Text>
              <Text
                color="teal.600"
                textTransform={"uppercase"}
                fontWeight={"semibold"}
                fontSize={"x-small"}
              >
                {`${orderHandlerName}`}
              </Text>
            </Box>
          </Box>
        </>
      )}
    </HStack>
  );
};

export default GaraazOrderComponentFooter;
