import {
  Popover,
  PopoverContent,
  OrderedList,
  ListItem,
  UnorderedList,
  Tooltip,
  Box,
  Text,
  HStack,
  IconButton,
  VStack,
} from "@chakra-ui/react";
import GarrazCopyText from "./GaraazCopyText";
import { GrDownload } from "react-icons/gr";
import { Subtitles } from "@mui/icons-material";

type Props = {
  value: {}[][];
  title: string;
  trigger?: any;
  CopierKeys: string[];
  ArekeysVisible: boolean[];
  children?: React.ReactNode;
  titleIcon?: any;
  placement?: any;
  subTitles: string[];
};

const GaraazGeneralPopoverV2: React.FC<Props> = (props: Props) => {
  const {
    trigger,
    title,
    value,
    CopierKeys,
    ArekeysVisible,
    children,
    titleIcon,
    placement,
    subTitles,
  } = props;

  return (
    <Popover
      returnFocusOnClose={false}
      placement={placement}
      trigger={trigger ?? "hover"}
    >
      {children}

      <PopoverContent style={{ padding: "10px", height: "100%" }}>
        <HStack mb="2">
          <Text
            color={"#4A5567"}
            fontSize={"lg"}
            fontWeight={"bold"}
            textTransform={"capitalize"}
          >
            {title}
          </Text>
          {titleIcon}
        </HStack>

        {value.map((typeValue, index1) => {
          return (
            <VStack key={`typeValue-${index1}`} alignItems={"flex-start"}>
              <HStack>
                <Text
                  color={"#4A5567"}
                  fontSize={"md"}
                  fontWeight={"bold"}
                  textTransform={"capitalize"}
                >
                  {subTitles[index1]}
                </Text>
              </HStack>
              {typeValue.length !== 1 && (
                <Box textColor={"#4A5567"}>
                  <OrderedList fontSize={"sm"}>
                    {typeValue?.map((item: any, index2: number) => (
                      <ListItem
                        borderTop={index2 >= 1 ? "1px solid lightgrey" : "none"}
                        key={index2}
                      >
                        <UnorderedList
                          key={index2}
                          listStyleType={"none"}
                          marginLeft={0}
                          paddingTop={index2 >= 1 ? "10px" : "0px"}
                          marginBottom={"1em"}
                        >
                          {Object?.entries(item)?.map(
                            ([key, value]: [any, any], index) => {
                              return (
                                <ListItem
                                  display={"flex"}
                                  key={index}
                                  alignItems={"center"}
                                >
                                  {ArekeysVisible[index1] && (
                                    <>
                                      {" "}
                                      <Text fontSize={"sm"} minWidth={"90px"}>
                                        {key}
                                      </Text>
                                      <Text fontSize={"sm"} marginRight={"5px"}>
                                        :
                                      </Text>{" "}
                                    </>
                                  )}

                                  <Tooltip label={`${value}`}>
                                    <Text fontSize={"sm"} noOfLines={1}>
                                      {value}
                                    </Text>
                                  </Tooltip>
                                  {CopierKeys.includes(key) && (
                                    <GarrazCopyText
                                      text={value}
                                      popOverMessage={`${key} Copied`}
                                    ></GarrazCopyText>
                                  )}
                                </ListItem>
                              );
                            }
                          )}
                        </UnorderedList>
                      </ListItem>
                    ))}
                  </OrderedList>
                </Box>
              )}

              {typeValue.length === 1 && (
                <Box textColor={"#4A5567"}>
                  <UnorderedList
                    listStyleType={"none"}
                    marginLeft={0}
                    paddingTop={"0px"}
                    marginBottom={"1em"}
                    fontSize={"sm"}
                  >
                    {Object.entries(typeValue[0]).map(
                      ([key, value]: [any, any], index) => {
                        return (
                          <ListItem
                            display={"flex"}
                            key={index.toString()}
                            alignItems={"center"}
                          >
                            {ArekeysVisible[index1] && (
                              <>
                                {" "}
                                <Text
                                  fontSize={"sm"}
                                  minWidth={"90px"}
                                  fontWeight={"normal"}
                                  textTransform={"capitalize"}
                                >
                                  {key}
                                </Text>
                                <Text marginRight={"5px"}>:</Text>{" "}
                              </>
                            )}
                            <Tooltip label={`${value}`}>
                              <Text
                                fontSize={"sm"}
                                noOfLines={1}
                                fontWeight={"normal"}
                                textTransform={"capitalize"}
                              >
                                {value}
                              </Text>
                            </Tooltip>
                            {CopierKeys.includes(key) && (
                              <GarrazCopyText
                                text={value}
                                popOverMessage={`${key} Copied`}
                              ></GarrazCopyText>
                            )}
                          </ListItem>
                        );
                      }
                    )}
                  </UnorderedList>
                </Box>
              )}

              {(!typeValue || typeValue.length === 0) && (
                <Text
                  color={"#4a5568"}
                  textTransform={"capitalize"}
                  fontWeight={"normal"}
                >
                  No {subTitles[index1]}
                </Text>
              )}
            </VStack>
          );
        })}
      </PopoverContent>
    </Popover>
  );
};

export default GaraazGeneralPopoverV2;
